<template>
  <div />
</template>

<script>
export default {
  created() {
    this.$store.dispatch('app/logoutAndReset', '')
  },
}
</script>
